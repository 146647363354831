import React, { useEffect, useState } from 'react';

import { NavLink, useParams } from 'react-router-dom';
import ApiService from '../Services/ApiService';
import moment from 'moment';
import PubSub from 'pubsub-js';

const UploadDetail = () => {
    const { id } = useParams();

    let [emails, setEmails] = useState([]);

    const getUploadDetail = async () => {
        showLoadingNotification(true);
        emails = await ApiService.getUploadDetail(id);
        showLoadingNotification(false);
        setEmails(emails);
    }

    const showLoadingNotification = (show) => {
        if (show) {
            PubSub.publish('show.loading.notification', {
                title: 'Loading...',
                message: 'Getting upload details...',
                show: true
            });
        } else {
            PubSub.publish('show.loading.notification', {
                show: false
            });
        }

    }

    useEffect(() => {
        getUploadDetail();
    }, [])

    const renderBounceStatus = (status) => {
        if (status === 'valid') {
            return <i class="fas fa-check text-success"></i>
        } else {
            return <i class="fas fa-times text-danger"></i>
        }
    }

    return (
        <div>
            <div className='container mb-5 mt-5'>

                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><NavLink to="/uploads">Uploads</NavLink></li>
                        <li class="breadcrumb-item active" aria-current="page">Details</li>
                    </ol>
                </nav>

                <h3 className='mb-4'>
                    <button
                        onClick={getUploadDetail}
                        className="btn btn-outline-primary btn-sm me-2">
                        <i class="fas fa-sync"></i>
                    </button>
                    Details
                </h3>
                <div className="card">

                    <div className="card-body">

                        <h5>Emails (<span className='fs-14 text-danger'>{emails.length}</span>)</h5>

                        <table className='table table-responsive table-bordered  small'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Initial Email Sent</th>
                                    <th>Initial Email Sent at</th>
                                    <th>Is Valid?</th>
                                    <th>Bounce checked at</th>
                                    <th>Subject</th>
                                    <th>Body</th>
                                </tr>
                            </thead>
                            <tbody>
                                {emails.map((email, index) => (
                                    <tr key={email.id}>
                                        <td>{index + 1}</td>
                                        <td>{email.email}</td>
                                        <td>{email.initial_email_sent ? 'Yes' : 'No'}</td>
                                        <td>{email.initial_email_sent_at ? moment(email.initial_email_sent_at).format('lll') : 'not sent yet'}</td>
                                        <td>{renderBounceStatus(email.bounce_status)}</td>
                                        <td>{email.verified_at ? moment(email.verified_at).format('lll') : 'not sent yet'}</td>
                                        <td>{email.subject || ''}</td>
                                        <td>{email.body || ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default UploadDetail;

